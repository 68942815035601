jQuery(function($){
	$(document).ready(function() {

	/**
	 * Code fired on each page
	 *
	 *	@author Przemysław Hernik
	 *	@date 03.02.2017
	 *	@see https://toddmotto.com/mastering-the-module-pattern/
	 */

 	initJS = (function(){

		var

			init = function(){
				_initNavPin();
				_clickableDropdownParent();
			},

			finalize = function(){

			},

			/**
			 * Manage placement of main navigation.
			 *	@author Przemysław Hernik
			 */
			_initNavPin = function(){
				if ($(window).width() > 1170) {

					var headerHeight = $('#main_navigation').height();

					$(window).on('scroll', {
							previousTop: 0
					},

					function() {
						var currentTop = $(window).scrollTop();
						//check if user is scrolling up
						if (currentTop < this.previousTop) {
							//if scrolling up...
							if (currentTop > 0 && $('.navbar-custom').hasClass('is-fixed')) {
								$('.navbar-custom').addClass('is-visible');
							} else {
								$('.navbar-custom').removeClass('is-visible is-fixed');
							}
						} else if (currentTop > this.previousTop) {
							//if scrolling down...
							$('.navbar-custom').removeClass('is-visible');
							if (currentTop > headerHeight && !$('.navbar-custom').hasClass('is-fixed')) $('.navbar-custom').addClass('is-fixed');
						}
						this.previousTop = currentTop;
					});
				}
			},

			/**
			 * Make dropdown parent clickable
			 *	By default BS4 blocks links which are dropdown parent.
			 *	@author Przemysław Hernik
			 */
			_clickableDropdownParent = function(){
				$("a.dropdown-toggle").click(function(){
					window.location.href = $(this).attr('href');
				});
			}
		;

		return {
			init: init,
			finalize: finalize
		};

	})();

	});
});
